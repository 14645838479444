/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const CartelaSCH = (perPage)=> {
    var filtro = new Filtro();
    filtro.perPage = perPage;
    filtro.fields = ['*'];
    filtro.addOrder('ordem','asc')
  return filtro

};
