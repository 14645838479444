/* eslint-disable */
import {mask} from 'vue-the-mask'
import router from '../router'
export default {
  directives: {mask},
  data: () => ({
    show_list: false,
    listaItens: [],
    info_alerta: '',
    cor_alerta: 'primary',
    show_alerta: false,
    precisao: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
      allowBlank: false,
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER
    },
  }),
  methods: {
    validarVideo(url){
      if (this.validarCampo(url)) {
        var regex_youtube = /^(http(s)??\:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtube\.com\/embed\/))([a-zA-Z0-9\-_])+/gm
        // var regex_instagram1 = /^(http(s)??\:\/\/)?(www\.)?((instagram\.com\/p\/)|(instagram\.com\/tv\/))([a-zA-Z0-9\-_])+(\/)/gm

        if (url.match(regex_youtube) !== null) {
          return true
          // } else if (url.match(regex_instagram1) !== null ) {
          //   return true
        } else{
          return false
        }
      }
    },
    inverterData(newData) {
      var xvals = '';
      if (this.validarCampo(newData)) {
        xvals = newData.split('-');
        return xvals[2] + '/' + xvals[1] + '/' + xvals[0]
      } else {
        return xvals
      }
    },
    reduzirData(newData) {
      var xvals = '';
      if (this.validarCampo(newData)) {
        xvals = newData.split('-');
        return xvals[2] + '/' + xvals[1]
      } else {
        return xvals
      }
    },
    // ALERTA
    open_alerta(text, cor) {
      this.info_alerta = text
      this.cor_alerta = cor
      this.show_alerta = true
      var _this = this
      var intervalo = setInterval(function () {
        _this.show_alerta= false
        clearInterval(intervalo)
      }, 5000)
    },
    fechar_alerta() {
      this.show_alerta = false
    },
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },
    removerMasK(str) {
      return this.validarCampo(str) ? str.replace(/[^0-9a-zA-Z]+/g, '') : ''
    },
    cortarString(str, tam) {
      if (this.validarCampo(str)) {
        if (str.length > tam) {
          str = str.slice(0, tam) + '...'
        }
      }
      return str
    },
    // MUDA COR DA LINHA
    isActive(id) {
      var toobar_dash = document.getElementsByClassName('toobar_dash')
      var lista = toobar_dash.item(0).children
      for(var i=0; i< lista.length; i++){
        var classes = lista[i].className.split(' ')
        var getIndex = classes.indexOf('toobar_is_active')

        if (lista[i].id === id){
          if (getIndex === -1) {
            classes.push('toobar_is_active')
            lista[i].className = classes.join(' ')
          }
        } else {
          if (getIndex > -1) {
            classes.splice(getIndex, 1)
            lista[i].className = classes.join(' ')
          }
        }
      }
    },
    chunkArray(myArray, n) {
      let tempArray = []
      let chunksize = Math.ceil(Math.max(myArray.length / n, 1))
      for (let index = 0; index < myArray.length; index += chunksize) {
        tempArray.push(myArray.slice(index, index + chunksize))
      }
      return tempArray
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    getValueByKey(item, key) {
      return item[key] === undefined ? item : item[key]
    },
    scroll_top(){
      var elmnt = document.getElementById("scrolling-techniques-7");
      elmnt.scrollTop = 0
    },
    getPos(array, item, tipo){
      var pos = array.indexOf(item)
      if (tipo === 1) {
        return pos === 0
      } else {
        return pos === (array.length-1)
      }
    },
    retira_acentos(str)
    {

      var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ!"

      var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr "
      var novastr="";
      for(var i=0; i<str.length; i++) {
        var troca=false;
        for (var a=0; a<com_acento.length; a++) {
          if (str.substr(i,1)===com_acento.substr(a,1)) {
            novastr+=sem_acento.substr(a,1);
            troca=true;
            break;
          }
        }
        if (troca===false) {
          novastr+=str.substr(i,1);
        }
      }
      return novastr;
      },
    scrollTo (id, block='start') {
      var elmnt = document.getElementById(id);
      if (this.validarCampo(elmnt)) {
        elmnt.scrollIntoView({behavior: "smooth", block: block});
      }
    },
    maskTel (cpf) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return cpf.replace(/(\d{2,3})(\d{4,5})(\d{4})/g, '(\$1)\$2\-\$3')
    },
    maskCep (cpf) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return cpf.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2\-\$3')
    },
    trocaIdToobar() {
      // navapp.classList.add("scroll");
      //   navapp.classList.remove("scroll");ent.getElementById('navapp')
      var navapp = document.getElementById('navapp')
      if (this.validarCampo(navapp)) {
        navapp.id = 'navapp_others'
      }
    },
    redimensionando(id){
        var teste_div = document.getElementById(id)
        var eleStyle = window.getComputedStyle(teste_div);
        var height = 760

        var hg = parseInt(eleStyle.width.replace('px', ''))-430
        if (parseInt(eleStyle.width.replace('px', ''))<=1100) {
          height = hg > 300 ? hg : 300
        } else {
          height = hg > 300 ? (hg > 760 ? 760 : hg) : 300
        }
        return height
    },
    ordenarLista(lista, key) {
      lista = lista.sort(function (a, b) {
        return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0
      })
      return lista
    },
    irParaScroll(path,id,sessao='') {
      sessionStorage.setItem(sessao, id)
      if (!this.validarCampo(id)) {
        this.scrollTo('inspire')
      }
      router.push({path:path}).catch(err => {
      })
    },
    IrParaUrl(path){
      var a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      a.click()
    },
    focusInput(id) {
      var loadTime = 10
      window.onload = function () {
        loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
      }
      var interval = setInterval(function () {
        var muniImpt = document.getElementById(id)
        if (muniImpt !== null) {
          muniImpt.focus()
        }

        clearInterval(interval)
      }, loadTime)
    },

    addEfeito(texto, efeito='fade-up'){
      if(this.validarCampo(texto)){
        return texto.replaceAll('>', " data-aos='"+efeito+"'>")
      } else {
        return texto
      }
    },
    voltar(path) {
      router.push({name: path}).catch(err => {
      })
    },
    px_to_vh(px){
      return parseInt(px * 0.13210039630118892)
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      var r =  parseInt(result[1], 16)
      var g =  parseInt(result[2], 16)
      var b =  parseInt(result[3], 16)
      return r + ', ' + g + ', ' + b + ', 0.5'
    },
    zeroEsquerda(value, totalWidth, paddingChar) {
      var length = totalWidth - value.toString().length + 1
      return Array(length).join(paddingChar || '0') + value
    },
    getDataAtual() {
      var d = this.zeroEsquerda(new Date().getDate(), 2)
      var m = this.zeroEsquerda(new Date().getMonth() + 1, 2)
      var a = new Date().getFullYear()
      return a + '-' + m + '-' + d
    },
    getHoraAtual() {
      var h = this.zeroEsquerda(new Date().getHours(), 2)
      var m = this.zeroEsquerda(new Date().getMinutes(), 2)
      return h + ':' + m
    },
    firstWord(str) {
      var array = []
      if (this.validarCampo(str)) {
        array = str.split(" ")
        return array[0]
      } else {
        return str
      }
    },
    toDecimal(v) {
      try {
        let ret = 0;
        if (this.validarCampo(v)) {
          ret = parseFloat(parseFloat(v).toFixed(2))
        }
        return ret
      } catch (e) {
        console.log(e)
      }
    },
    updateTexto(txt,valor){
      return this.validarCampo(txt) ? txt : valor
    },
    retornoValido(campo,key, retorno) {
      if (this.validarCampo(campo)) {
        return this.validarCampo(campo[key]) ? campo[key] : retorno
      } else {
        return ''
      }
    },
  },
}
