<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-dialog v-model="Mostrar" :width="width" persistent :fullscreen="fullscreen" :hide-overlay="overlay">
      <v-card>
        <v-card-title class="headline" primary-title id="modalhead" style="background: #F47C20; color: white">
          {{getTitulo()}}
          <v-spacer></v-spacer>
          <v-btn icon @click="fechar()" dark><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */

  export default {
    props: {
      titulo: {
        default: ''
      },
      modal: {
        default: false
      },
      fullscreen: {
        default: false
      },
      width: {
        default: '50%'
      }
    },
    name: "ModalCadastro",

    data() {
      return {
        overlay: false,
        margem_left: '25',
        classe: '',
      }
    },
    methods: {
      fechar() {
        this.$emit('fechar')
      },
      validarCampo(campo) {
        if (campo !== undefined && campo !== null && campo !== '') {
          return true
        } else {
          return false
        }
      },
      getTitulo() {
        if (this.validarCampo(this.titulo)) {
          return this.titulo
        } else {
          return this.$router.currentRoute.meta
        }
      },
    },
    computed: {
      Mostrar: {
        get() {
          return this.modal
        },
        set() {
          return this.Mostrar
        }
      },
    },
  }
</script>
