/* eslint-disable */
import {processar} from '../../services/GenericAPI'

const url = 'contato'

const actions = {
  async processarContato({commit}, {contato, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, contato, acao)
      onSucesso()
    } catch (error) {
      console.log('ERROR: ', error)
      onErro()
    }
  },
}

export default {
  namespaced: true,
  actions,
}
