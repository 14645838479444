/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, nao_autorizado} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {CartelaSCH} from "../../search/CartelaSCH"
import Cartela from '../../models/cartela'


const url = '/cartela';

const state = {
  all: [],
  filtro: new Filtro()
};
const getters = {
  listaCartela: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CartelaSCH(perPage))
  },

  async setAllCartela({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_CARTELAS', list);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      // this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }

  },
};

const mutations = {
  SET_CARTELAS(state, cartelas) {
    state.all = cartelas
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

