/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Evento {

  constructor () {
    this.evento_id = uuidV4();
    this.descricao = ''
    this.dt_evento= ''
    this.imagens= [];
    this.urls_imagem= [];
    this.eventos_videos= [];
  }
}
