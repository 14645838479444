/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getBaseSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro();
  filtro.perPage = 1;
  filtro.filters.addFilter(campo,valor,condicao);
  return filtro
};

export const ClienteNome = (nome)=> {
    var array = []
  if (nome !== undefined && nome !== null && nome !== '') {
      array = nome.split(" ")
      return array[0]
    } else {
      return nome
    }
}
export const ClienteSCH = (perPage)=> {
    var filtro = new Filtro();
    filtro.perPage = perPage;
    filtro.fields = ['*'];
  return filtro

};
