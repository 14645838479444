/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import geral from './geral'
import cliente from './modules/clientes'
import banner from './modules/banners'
import evento from './modules/eventos'
import quem_somos from './modules/quem_somos'
import consultoria from './modules/consultorias'
import sistema from './modules/sistemas'
import contato from './modules/contatos'
import cartela from './modules/cartelas'



Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    geral,
    banner,
    evento,
    cliente,
    contato,
    cartela,
    sistema,
    consultoria,
    quem_somos,
  }
})
