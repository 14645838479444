<template>
  <v-app id="app">
    <v-main>
    <router-view/>

    </v-main>

    <rodape></rodape>
    <PopupWhats></PopupWhats>
  </v-app>
</template>
<script>
/* eslint-disable */
  import AOS from 'aos'
  export default {
    name: 'App',
    components: {
      PopupWhats: () => import('./components/PopupWhats.vue'),
      Rodape: () => import('./components/Rodape.vue')
    },
    created () {
      AOS.init()
    }
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
