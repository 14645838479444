<template>
  <div>
    <!--<div class="thumb-example">-->
      <!--&lt;!&ndash; swiper1 &ndash;&gt;-->
      <!--<swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">-->
        <!--<swiper-slide class="slide-1"></swiper-slide>-->
        <!--<swiper-slide class="slide-2"></swiper-slide>-->
        <!--<swiper-slide class="slide-3"></swiper-slide>-->
        <!--<div class="swiper-button-next swiper-button-white" slot="button-next"></div>-->
        <!--<div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>-->
      <!--</swiper>-->
      <!--&lt;!&ndash; swiper2 Thumbs &ndash;&gt;-->
      <!--<swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">-->
        <!--<swiper-slide class="slide-1"></swiper-slide>-->
        <!--<swiper-slide class="slide-2"></swiper-slide>-->
        <!--<swiper-slide class="slide-3"></swiper-slide>-->
      <!--</swiper>-->
    <!--</div>-->
    <!--{{lista}}-->
   <v-card height="650px">
     <div class="thumb-example">
       <!-- swiper1 -->
         <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
           <swiper-slide class="slide-1" v-for="(item, index) in lista.urls_imagem" :key="'item' + index">
             <a :href="selecionaImagem(lista.urls_imagem, 'original',item.anexo)" data-lightbox="image-1" data-title="">
               <v-img :src="selecionaImagem(lista.urls_imagem, 'original',item.anexo)" height="100%" contain></v-img>
             </a>
           </swiper-slide>

           <v-btn dark icon class="swiper-button-next " slot="button-next"></v-btn>
           <v-btn dark icon class="swiper-button-prev " slot="button-prev"></v-btn>
           <!--<div class="swiper-button-next swiper-button-white" slot="button-next"></div>-->
           <!--<div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>-->
         </swiper>
       <br>
       <v-divider></v-divider>
         <!-- swiper2 Thumbs -->
         <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
           <swiper-slide class="slide-1" v-for="(thumbs, index) in lista.urls_imagem" :key="'Thumbs' + index">
             <v-img :src="selecionaImagem(lista.urls_imagem, '200',thumbs.anexo)" height="100%" contain></v-img>
           </swiper-slide>

         </swiper>
       <br>
     </div>
   </v-card>
  </div>

</template>

<script>
/* eslint-disable */
  // import Util from '../../assets/javascript/Util'
  // import empty from '../../assets/img/empty.png'
  // import miscImageCropper from '../../helpers/img-cropper'
import miscMixin from "../helpers/misc";
import miscImageCropper from './cropper/img-cropper'


  export default {
    // mixins: [miscImageCropper],
    mixins: [miscMixin, miscImageCropper],
    name: 'ProSwiper',
    props:['lista'],
    // components: {
    //   Swiper,
    //   SwiperSlide
    // },

    // props: ['imagensList', 'display', 'classe', 'width', 'heightImg', 'exibir'],
    data: () => ({
      swiperOptionTop: {
        loop: false,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: false,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      }
    }),
    computed:  {
      // swiper() {
      //   return this.$refs.swiperTop.$swiper
      // }
    },

    mounted() {
      // this.swiper.slideTo(1, 1000, false)
      // this.$refs.swiperTop.$swiper.slideTo(1, 1000, false)

      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      })
    }
  }
</script>
<style lang="scss" scoped>

  .swiper-button-next, .swiper-button-prev{
    display: none;
  }
  .thumb-example:hover .swiper-button-next,
  .thumb-example:hover .swiper-button-prev{
    color: orangered;
    display: block;
    transition: 1s ease-in-out ;
  }
  .thumb-example {
    position: relative;
    height: 600px;
    /*background-color: #1a1a1a;*/
    z-index: 0!important;
    padding: 5px;
  }
  .swiper {
    .swiper-slide {
      background-size: cover;
      background-position: center;
      &.slide-1 {
        /*background-color: #2d2d2d;*/
        /*background-image:url('../assets/img/temp/fundo.jpg');*/
      }
    }
    &.gallery-top {
      height: 80%;
      width: 100%;
    }
    &.gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0px;
    }
    &.gallery-thumbs .swiper-slide {
      width: 15%;
      height: 100%;
      opacity: 0.4;
    }
    &.gallery-thumbs .swiper-slide-active {
      opacity: 1;
    }
  }
</style>
