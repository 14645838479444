/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from "./Dash";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: Dash,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue')
        },
        {
          path: '/quem_somos',
          name: 'QuemSomos',
          component: () => import(/* webpackChunkName: "QuemSomos" */ './views/quem_somos/QuemSomos.vue')
        },
        {
          path: '/eventos',
          name: 'Eventos',
          component: () => import(/* webpackChunkName: "Evento" */ './views/eventos/Evento.vue')
        },
        {
          path: '/contatos',
          name: 'Contatos',
          component: () => import(/* webpackChunkName: "Contatos" */ './views/contatos/Contatos.vue')
        },
        {
          path: '/consultoria',
          name: 'Consultoria',
          component: () => import(/* webpackChunkName: "Consultoria" */ './views/consultoria/Consultoria.vue')
        },
        {
          path: '/cartela',
          name: 'Cartela',
          component: () => import(/* webpackChunkName: "Cartela" */ './views/cartela/Cartela.vue')
        },
        {
          path: '/sistema',
          name: 'Sistema',
          component: () => import(/* webpackChunkName: "Sistemas" */ './views/sistemas/Sistemas.vue')
        },
        {
          path: '/login',
          name: 'Login',
          component: () => import(/* webpackChunkName: "about" */ './views/login/Login.vue')
        },
        {
          path: '/registrar',
          name: 'Registrar',
          component: () => import(/* webpackChunkName: "about" */ './views/login/Registrar.vue')
        },
        {
          path: '/montar_cartela',
          name: 'MontarCartelaSimples',
          component: () => import(/* webpackChunkName: "about" */ './views/montar_cartela/MontarCartelaSimples.vue')
        },
        {
          path: '/minha_conta',
          name: 'MinhaConta',
          component: () => import(/* webpackChunkName: "about" */ './views/login/minha_conta/MinhaConta.vue')
        },
        {
          path: '/minha_conta/meus_pedidos',
          name: 'MeusPedidos',
          component: () => import(/* webpackChunkName: "about" */ './views/login/minha_conta/MeusPedidos.vue')
        },
        {
          path: '/alterar_senha',
          name: 'AlterarSenha',
          component: () => import(/* webpackChunkName: "about" */ './views/login/AlterarSenha.vue')
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
    }
  ]
})
