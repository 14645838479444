/* eslint-disable */
import {lista, processar, autentica, pegaLista, setarToken, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {ClienteSCH, getBaseSCH,ClienteNome} from "../../search/ClienteSCH"

import {EventBus} from '../../helpers/event-bus'
import _ from 'lodash'
import Cliente from '../../models/cliente'

const url = '/cliente'

const state = {
  all: [],
  cliente: new Cliente(),
  filtro: new Filtro()
}

const getters = {
  listaClientes: (state) => {
    return state.all
  },
  listaClientesSemFiltro: (state) => {
    return state.all
  },
  pegaClienteId: (state) => (id) => {
    let mun = state.all.find(cliente => cliente.user_id === id)
    return mun !== null ? mun.descricao : '** NÃO ENCONTRADO'
  },

  pegaCliente: (state) => {
    return state.cliente !== null ? state.cliente : new Cliente()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['nome', 'email'],
       '%'+search+'%',
        'ilike'
      )
  },
  async getItem({commit}, {id, onSucesso, onErro}) {
    try {

      var filtro = getBaseSCH('cliente_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      // console.log('CLIENTE: ',list.data[0])
      commit('SET_CLIENTE', list.data[0])
      onSucesso()
    } catch (error) {
      onErro()
    }

  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', ClienteSCH(perPage))
  },
  setCliente({commit}, cliente) {
     commit('SET_CLIENTE', cliente)
  },
  async setAllClientes({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      // list.data.forEach(function (l) {
      //   l.password = ''
      //   l.password_conf = ''
      // })
      // console.log('SET_USUARIOS: ', list.data)
      commit('SET_USUARIOS', list)
      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }
  },
  async processarCliente({commit}, {cliente, onSucesso, onErro}) {
    try {
      let res = await processar(url + '/commit' , cliente, 'gravar');
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log(error.response);
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  // async enviarEmailParaCliente({commit}, {cliente,link, onSucesso, onErro}) {
  // },
  async processarClientePedido({commit}, {cliente,link, onSucesso, onErro}) {
    try {
      // console.log('processarClientePedido: ',cliente)
      let res = await processar(url + '/'+link , cliente, 'gravar');
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log(error.response);
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async autenticarCliente({commit}, {cliente, onSucesso, onErro}) {
    try {
      console.log('autenticarCliente');
      let res = await autentica(url + '/auth', cliente)
      sessionStorage.setItem('cliente_id',res.data.cliente.cliente_id)
      this.dispatch('geral/setNomeUsuario', ClienteNome(res.data.cliente.nome))
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro(error)
    }
  },
  async validarCliente({commit}, {cliente, onSucesso, onErro}) {
    try {
      // console.log('validarCliente');
      let res = await autentica(url + '/validar', cliente)
      sessionStorage.setItem('cliente_id',res.data.cliente.cliente_id)
      this.dispatch('geral/setNomeUsuario', ClienteNome(res.data.cliente.nome))
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro(error)
    }
  }
}

const mutations = {
  SET_CLIENTE(state, cliente) {
    state.cliente = cliente
  },
  SET_USUARIOS(state, clientes) {
    state.all = clientes
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
