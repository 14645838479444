/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, nao_autorizado} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {QuemSomosSCH} from "../../search/QuemSomosSCH"
import QuemSomos from '../../models/QuemSomos'


const url = '/quem_somos';

const state = {
  all: [],
  filtro: new Filtro()
};
const getters = {
  listaQuemSomos: (state) => {
    return state.all !== undefined ? state.all.data[0] : new QuemSomos()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', QuemSomosSCH(perPage))
  },

  async setAllQuemSomos({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_QUEMSOMOS', list);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      // this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }

  },
};

const mutations = {
  SET_QUEMSOMOS(state, quem_somoss) {
    state.all = quem_somoss
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

