/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '../src/assets/css/main.scss'
import 'font-awesome/css/font-awesome.min.css'
import 'lightbox2/dist/css/lightbox.css'
import 'aos/dist/aos.css'
import lightbox from 'lightbox2'
import VueCropper from 'vue-cropperjs'
import store from './store/index'
import * as VueGoogleMaps from 'vue2-google-maps'
// import 'swiper/css/swiper.css'
import ProCropper from './components/cropper/CropperImagem.vue'

// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Parallax from '../src/components/Parallax.vue'

import ScrollAnimation from './assets/js/scrollanimation'
import {sync} from 'vuex-router-sync'
import ProSwiper from './components/ProSwiper.vue'
import ProCard from '../src/components/ProCard.vue'
import ProAlerta from '../src/components/ProAlerta.vue'
import TabelaSimples from '../src/components/TabelaSimples.vue'
import ProContainer from '../src/components/ProContainer.vue'
import OwlCarousel from 'v-owl-carousel'
import Carousel3d from 'vue-carousel-3d';
import ModalCadastro from './components/ModalCadastro.vue'
import ImgCardFlip from './components/ImgCardFlip.vue'
import currency from 'v-currency-field'

Vue.use(currency)
Vue.use(Carousel3d);
Vue.component('pro-cropper', ProCropper)
Vue.component(VueCropper);
Vue.component('pro-alerta', ProAlerta)
Vue.component('pro-card', ProCard)
Vue.component('table-simples', TabelaSimples)
Vue.component('pro-container', ProContainer)
Vue.use(lightbox)
// Vue.component('swiper', Swiper)
// Vue.component('swiperSlide', SwiperSlide)
// Vue.component('pro-swiper', ProSwiper)
Vue.component('carousel', OwlCarousel)
Vue.component('parallax', Parallax)
Vue.component('pro-modal', ModalCadastro)
Vue.component('img-card-flip', ImgCardFlip)


Vue.directive('scrollanimation', ScrollAnimation);

Vue.use(Vuetify, {
  theme: {
    secondary: '#0a593b',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
})

Vue.config.productionTip = false
// A key precisará ser alterada depois
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAb0wzUYfv-8mxhDsIS8nv7ddodU-Df5Eo',
    libraries: 'visualization'
  }
})


sync(store, router)

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi'
  }
}

new Vue({
  router,
  store: store,
  render: h => h(App),
  vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app')
