<template>
  <div>
    <v-container :fluid="fluid">
      <v-card :flat="flat">
        <v-card-title v-if="title">
          <slot name="title"></slot>
        </v-card-title>
        <v-card-text>
          <v-row :justify="justificar">
            <slot name="content"></slot>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="actions">
          <slot name="acao"></slot>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    name: 'ProContainer',
    props: {
      justificar: {default: 'start'},
      flat: {default: false},
      fluid: {default: false},
      title: {default: false},
      actions: {default: false},
    },
    data: () => ({}),
    methods: {},
    components: {},
    computed: {},
    created() {
    },
    mounted() {
    },
    watch: {}
  }
</script>

<style scoped>

</style>
