<template>
  <div>
    <v-snackbar v-model="Mostrar" :timeout="tempo" top="top" :color="cor">
      {{ texto }}
      <v-btn text @click="fechar">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'ProAlerta',
  props: {
    texto: {
      type: String,
      default: 'Texto'
    },
    cor: {
      type: String,
      default: 'red'
    },
    show: {
      type: Boolean,
      default: false
    },
    tempo: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      snackbar: false
    }
  },
  methods: {
    abreModal () {
      // this.$emit('abrirmodal', this.snackbar)
    },
    fechar () {
      this.$emit('abriAlerta', false)
    }
  },
  computed: {
    Mostrar: {
      get () {
        return this.show
      },
      set () {
        return this.Mostrar
      }
    }
  }
}
</script>
