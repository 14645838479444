<template>
  <div class="flip-container"  :style="'height:' +height">
    <v-card :class="'flipper'+tipo" elevation="3">
      <v-img class="front" :src="front" :height="height"/>
      <v-card-text :class="'back'+tipo" :style="'height:'+height ">
        <v-row align="center" :style="'height:'+height ">
          <v-col align-self="center" cols="12">
            <slot></slot>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    name: 'ImgCardFlip',
    props: ['front', 'back', 'tipo','height'],
    data: () => ({}),
    methods: {},
    components: {},
    computed: {},
    created() {
    },
    mounted() {
    },
    watch: {}
  }
</script>

<style scoped>

  .flip-container {
    perspective: 1000px;
  }

  .flipperY, .flipperX {
    position: relative;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .front, .backY{
    position: absolute;
    backface-visibility: hidden;
  }

  .backX {
    backface-visibility: hidden;

  }

  .flip-container:hover .flipperY {
    transform: rotateY(180deg);
  }
  .backY {
    transform: rotateY(180deg);
    background: orange;
    color: white !important;
  }
  .flip-container:hover .flipperX {
    transform: rotateX(180deg);
  }

  .backX {
    transform: rotateX(180deg);
    background: orange;
    color: white !important;
  }


</style>
