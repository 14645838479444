<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform" xmlns:v-scroll="http://www.w3.org/1999/xhtml">
  <div id="inspire" class="scrolling nexa" >
    <v-card class="scroll-y" style="background: transparent!important;" flat>
      <!--DESKTOP-->
      <v-app-bar absolute dark :class="dark ? 'gradiente_laranja' : 'gradiente_laranja_dark'"
                 scroll-target="#scrolling-techniques-7" class="appScrolling desktop " id="navapp" :elevation="dark ? 0 : 3">

        <v-app-bar-nav-icon class="mobile" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-tabs id="tab_home" v-model="tab" :hide-slider="tab==='/'" right :color="dark ? '#fff' : '#fff'"
                style="padding-left: 5%; padding-right: 5%" active-class="ativo">
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab class="text_tab" to="/">Home</v-tab>
          <v-tab class="text_tab" to="/quem_somos">Quem Somos</v-tab>
          <v-tab class="text_tab" @click="irParaScroll('cartela', '','cartelaScroll')" >Cartelas</v-tab>
          <v-tab class="text_tab" @click="irParaScroll('sistema', '','sistemaScroll')" >Sistemas</v-tab>
          <v-tab class="text_tab" to="/consultoria">Consultoria</v-tab>
          <v-tab class="text_tab" to="/eventos">Eventos</v-tab>
          <v-tab class="text_tab" to="/contatos">Contatos</v-tab>
          <v-tab class="text_tab" :to="isLogado ? '/minha_conta' : '/login'"> {{ isLogado ? 'Minha Conta' : 'Login' }}</v-tab>
        </v-tabs>
        <v-menu offset-y  left bottom v-if="isLogado">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon  v-on="on"   v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="sair()">
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <!--MOBILE-->
      <v-app-bar  dark fixed
                  class="gradiente_laranja_dark mobile">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" style="z-index: 1"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-btn v-if="!isLogado" outlined  to="/login"> Login</v-btn>
          <span v-if="isLogado" >{{nome_usuario}}</span>

          <v-menu v-if="isLogado"  offset-y  left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon  v-on="on"   v-bind="attrs">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="irPara()">
                <v-list-item-title>Minha Conta</v-list-item-title>
              </v-list-item>
              <v-list-item @click="sair()">
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

      </v-app-bar>

      <v-navigation-drawer v-model="drawer" fixed temporary class="gradiente_" style="height: 100%">
        <v-list dark nav dense>
          <br>
          <v-list-item  v-if="!isLogado">
            <v-list-item-title>
              <v-btn outlined block to="/login"> Login</v-btn>
            </v-list-item-title>
          </v-list-item>
          <!--isLogado-->
          <v-list-group :value="false" no-action  v-else>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{nome_usuario}}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item  to="/minha_conta">
              <v-list-item-title>Minha Conta</v-list-item-title>
            </v-list-item>
            <v-list-item @click="sair()">
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>

          </v-list-group>


          <v-divider></v-divider>

          <v-list-item @click="irParaRota(m.path)" v-for="(m,index) in menu" :key="'menu'+index">
            <v-list-item-title>{{m.titulo}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-sheet style="background: transparent!important;" id="scrolling-techniques-7">
        <br>
        <div id="scroll-target" v-scroll:#scrolling-techniques-7="onScroll"
             style="background: transparent!important; ">
          <router-view/>
        </div>
      </v-sheet>
    </v-card>

    <!-- Dialog que vai exibir a lista de erros caso aja uma validação -->
    <v-dialog v-model="dialogError" width="50%">
      <v-card v-if="objMsgError !== null">
        <v-card-title class="headline">{{ objMsgError.message }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="objMsgError.details !== null">
            <v-container style="text-align: left">
              <ul :key="index" v-for="(message, index) in objMsgError.details">
                <li> {{ message.mensagem }}</li>
              </ul>
            </v-container>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popup que vai exibir mensagens de sucesso -->
    <v-snackbar v-model="showSnack" top color="success" :timeout="2000">
      {{ msgSuccess }}
      <template v-slot:actions>
        <v-icon @click="toggleSnackSuccess">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */

import {mapActions, mapState} from 'vuex'
import router from './router'
import miscMixin from './helpers/misc'

export default {
  mixins: [miscMixin],
    name: 'Dash',
    data: () => ({
      tab: 0,
      fab: false,
      drawer: false,
      dark: true,
      menu:[
        {titulo: 'Home', path:'/'},
        {titulo: 'Quem Somos', path:'/quem_somos'},
        {titulo: 'Cartelas', path:'/cartela'},
        {titulo: 'Sistemas', path:'/sistema'},
        {titulo: 'Consultoria', path:'/consultoria'},
        {titulo: 'Eventos', path:'/eventos'},
        {titulo: 'Contatos', path:'/contatos'},
        // {titulo: 'Login', path:'/login'},
      ],
    }),

    methods: {
      ...mapActions('geral', ['toggleDialogError', 'toggleSnackSuccess', 'setTab','toggleisLogado','setNomeUsuario']),
      sair(){
        sessionStorage.clear()
        this.toggleisLogado()
        this.setNomeUsuario('')
        router.push({path: '/'}).catch(err => {
        })
      },
      irParaRota(path){
        router.push({path: path}).catch(err => {
        })
      },
      irPara(){
        router.push({path: '/minha_conta'}).catch(err => {
        })
      },
      onScroll(e) {
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.fab = top > 20
      },
      toTop() {
        this.$vuetify.goTo(0)
      }
    },
    components: {
      Rodape: () => import('./components/Rodape.vue')
    },
    mounted() {
      // Permite usar o AOS com vuetify
      var inspire = document.getElementById("inspire");
      var _this= this
      window.onscroll = function() {
        "use strict";
        if (document.documentElement.scrollTop >= 50) {
          _this.dark = false
          inspire.classList.add("scrolling");
        } else {
          _this.dark = true
          inspire.classList.remove("scrolling");
        }
      };
    },
    computed: {
      ...mapState('geral', {
        isLogado: 'isLogado',
        nome_usuario: 'nome_usuario',
        dialogError: 'dialogError',
        objMsgError: 'objMsgError',
        snackSuccess: 'snackSuccess',
        msgSuccess: 'msgSuccess'
      }),
      showSnack: {
        get() {
          return this.snackSuccess
        },
        set() {
          this.toggleSnackSuccess()
        }
      }
    },
  }
</script>
<style scoped lang="scss">

  .appScrolling{
    z-index: 100;
    position: fixed !important;
  }

  .scrolling {
    /*
   #navapp {
     box-shadow:  0 2px 4px -1px  rgba(0,0,0,.2),0 0 0 0 rgba(0,0,0,.14),0 0 0 0 rgba(0,0,0,.12)!important;
   }
    */
 }
</style>
